<template>
  <v-footer class="sub-footer-container">
    <v-col class="sub-footer-inner">
      <v-row class="py-5">
        <div>
          <v-img :src="require('@/assets/images/footerlogo.png')" width="180"></v-img>
        </div>
      </v-row>
      <v-row>
        <v-col lg="4" md="4" sm="6" class="center-content mt-2">
          <v-row class="sub-footer-title">QUI SOMMES-NOUS ?</v-row>
          <v-row class="sub-footer-para-text-size">
            <p>Chez Portail Marchés, nous sommes des experts en matière de réponse aux appels d'offres.</p>
            <p>Grâce à nos services, votre entreprise va optimiser du temps pour vos autres objectifs.</p>
          </v-row>
          <v-row>
            <v-col md="1" sm="1" class="mr-1" style="margin-left: 0;">
              <div class="mx-auto social-icons">
                <img alt="facebook" :src="require('@/assets/icons/facebook.svg')" />
              </div>
            </v-col>
            <v-col md="1" sm="1" class="mr-1">
              <div class="mx-auto social-icons">
                <v-img :src="require('@/assets/icons/linkedin.svg')" class="text-white" />

              </div>
            </v-col>
            <v-col lg="1" md="1" sm="1" class="mr-1">
              <div class="mx-auto social-icons">
                <img :src="require('@/assets/icons/instagram.svg')" class="text-white" />
              </div>
            </v-col>
            <v-col lg="1" md="1" sm="1" class="mr-1">
              <div class="mx-auto social-icons">
                <img :src="require('@/assets/icons/mail.svg')" class="text-white">
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="3" md="3" sm="3" class="px-0 mr-3">
              <img :src="require('@/assets/icons/googleStore.svg')" class="text-white store-icons">
            </v-col>
            <v-col lg="3" md="3" sm="3" class="px-0 ml-1">
              <img :src="require('@/assets/icons/appStore.svg')" class="text-white store-icons" />
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="5" md="5" sm="6" class="mt-2">
          <v-row>
            <v-col>
              <v-row class="sub-footer-title">CARTE GÉOGRAPHIQUE</v-row>
              <v-row>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d84029.4343470903!2d2.2429357!3d48.840668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sde!4v1677955673376!5m2!1sen!2sde"
                  width="250" height="200" style="border:1px;" allowfullscreen="" loading="lazy">
                </iframe>
              </v-row>
            </v-col>

          </v-row>
        </v-col>
        <v-col lg="3" md="3" sm="12" class="center-content mt-4">
          <v-row>
            <v-col>
              <v-row class="sub-footer-title">CONTACT</v-row>
              <v-row class="sub-footer-para-text-size">
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et justo ipsum.
                  Nulla facilisi. Sed non sapien quis dui maximus vestibulum.
                  Donec maximus ligula sed faucibus dictum.
                  Curabitur nec justo justo.</p> -->
              </v-row>
              <v-row class="d-flex align-center flex-column flex-md-row">
                <v-col cols="auto" class="d-flex align-center">
                  <v-img :src="require('@/assets/icons/map.svg')" width="15"></v-img>
                  <span class="py-2 sub-footer-para-ref ml-2">xxxxxxxx</span>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <v-divider class="border-opacity-25" thickness="2"></v-divider>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center flex-column flex-md-row">
                <v-col cols="auto" class="d-flex align-center">
                  <v-img :src="require('@/assets/icons/mail.svg')" width="15"></v-img>
                  <span class="py-2 sub-footer-para-ref ml-2">info@cybermarche.fr</span>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <v-divider class="border-opacity-25" thickness="2"></v-divider>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center flex-column flex-md-row">
                <v-col cols="auto" class="d-flex align-center">
                  <v-img :src="require('@/assets/icons/phone.svg')" width="15"></v-img>
                  <span class="py-2 sub-footer-para-ref pl-2">33 719302103 / 01930 210315</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>

export default {
  name: 'SubFooterView',
  data() {
    return {}
  },
  methods: {}


}
</script>
<style scoped>
.sub-footer-container {
  color: #DCE3ED !important;
  background-color: #304353 !important;
}

.sub-footer-inner {
  padding-left: 14.71rem !important;
  padding-right: 14.71rem !important;
  margin: auto;
}

.sub-footer-text-size {
  color: #FFF;
  font-family: Rubik-Regular;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
}

.sub-footer-para-text-size {
  font-family: Rubik-Regular;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  width: 16rem;
  line-height: 1.2rem;
}

.sub-footer-para-ref {
  font-family: Rubik-Regular;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
}

.social-icons {
  width: 1.3655rem;
  height: 1.3655rem;
  flex-shrink: 0;
}

.sub-footer-title {
  color: #DCC6A5;
  font-family: Rubik-Regular;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 800;
  /* 125% */
  letter-spacing: 0.07031rem;
  margin-bottom: 1.49rem !important;
}

.store-icons {
  width: 5.99063rem;
  height: 2.04981rem;
  flex-shrink: 0;
  border-radius: 0.3115rem;
}

.center-content {
  margin: auto;
  padding: 10px;
}

/*@mediaSection*/

@media (max-width: 600px) {
  .sub-footer-inner {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    margin-top: 3.34rem;
  }
}

@media (orientation: landscape)  and (max-height: 500px){
  .sub-footer-inner {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sub-footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .store-icons {
    width: 4.5rem;
    height: 1.8rem;
  }

  .social-icons {
    width: 1.2rem;
    height: 1.2rem;
  }

  
}
</style>